<template>
  <div class="h-full flex">
    <div
      class="flex-1 flex flex-col justify-center py-20 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-36"
    >
      <img class="absolute left-12 top-8" src="@/assets/logos/csm.svg" />
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <h2 class="text-5xl font-semibold mt-6">Invoicing Tool</h2>
        </div>
        <div class="mt-12">
          <router-view />
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <div
        :style="{
          backgroundImage: `url(${require('@/assets/backgrounds/splash.png')}`,
        }"
        class="absolute h-full w-full bg-no-repeat bg-cover"
      ></div>
    </div>
  </div>
</template>
